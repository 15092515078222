<template>
  <van-cell title="批量设置" style="margin:0 0 0 0;background:#fff;" />
  <van-row>
    <van-col span="12">
      <van-field label-width="4rem" v-model="batch.price" label="价格" placeholder="请输入" type="number" />
    </van-col>
    <van-col span="12">
      <van-field label-width="4rem" v-model="batch.stock" label="库存" placeholder="请输入" type="digit" />
    </van-col>
  </van-row>
  <template v-for="item in everyDays" :key="item">
    <van-cell :title="item.day" style="margin:7px 0 0 0;background:#fff;" />
    <van-row>
      <van-col span="12">
        <van-field label-width="4rem" v-model="item.price" label="价格" placeholder="请输入" type="number" />
      </van-col>
      <van-col span="12">
        <van-field label-width="4rem" v-model="item.stock" label="库存" placeholder="请输入" type="digit" />
      </van-col>
    </van-row>
  </template>
  <van-action-bar>
    <van-action-bar-button type="danger" text="保存" @click="onSave" />
  </van-action-bar>
</template>

<script>
import { toRefs, reactive, onMounted, watch } from 'vue'
import { Toast } from 'vant'
import dayjs from 'dayjs'

export default {
  props: {
    days: Array
  },
  emits: ['save'],
  setup (props, { emit }) {
    const state = reactive({
      batch: {
        price: '',
        stock: ''
      },
      everyDays: [],
      selectedDays: JSON.parse(JSON.stringify(props.days))
    })
    const listEveryDay = (stime, etime) => {
      var diffdate = []
      var i = 0
      while (stime <= etime) {
        diffdate[i] = stime
        var stimeTs = new Date(stime).getTime()
        var nextDate = stimeTs + (24 * 60 * 60 * 1000)
        var nextDatesY = new Date(nextDate).getFullYear() + '-'
        var nextDatesM = (new Date(nextDate).getMonth() + 1 < 10) ? '0' + (new Date(nextDate).getMonth() + 1) + '-' : (new Date(nextDate).getMonth() + 1) + '-'
        var nextDatesD = (new Date(nextDate).getDate() < 10) ? '0' + new Date(nextDate).getDate() : new Date(nextDate).getDate()
        stime = nextDatesY + nextDatesM + nextDatesD
        i++
      }
      return diffdate
    }
    const onSave = () => {
      emit('save', state.everyDays)
    }
    watch(() => state.batch.price, (to, previous) => {
      for (const item of state.everyDays) {
        item.price = to
      }
    }, { deep: true })
    watch(() => state.batch.stock, (to, previous) => {
      for (const item of state.everyDays) {
        item.stock = to
      }
    }, { deep: true })
    const init = () => {
      console.log('::::::::')
      console.log(state.selectedDays)
      const firstDay = dayjs().format('YYYY-MM-DD')
      const lastDay = dayjs().add(90, 'day').format('YYYY-MM-DD')
      Toast.loading({ forbidClick: true })
      for (const item of listEveryDay(firstDay, lastDay)) {
        const hasDay = state.selectedDays.filter(v => v.day === item)
        state.everyDays.push({
          day: item,
          price: hasDay.length > 0 ? hasDay[0].price : '',
          stock: hasDay.length > 0 ? hasDay[0].stock : ''
        })
      }
      Toast.clear()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSave
    }
  }
}
</script>
