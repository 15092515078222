<template>
  <van-nav-bar
    title="商品规格"
    left-text="返回"
    left-arrow
    @click-left="onClickLeft"
  />
  <template v-for="(item, index) in specs" :key="item">
    <van-cell :title="item.name" style="margin:15px 0 0 0;" />
    <van-checkbox-group v-model="item.selected">
      <div v-for="item2 in item.options" :key="item2" style="padding:15px;background:#fff;" class="van-hairline--top-bottom">
        <van-checkbox :name="item2"><h5>{{ item2 }}</h5></van-checkbox>
      </div>
    </van-checkbox-group>
    <van-field
      v-model="item.custom"
      center
      clearable
      label="自定义规格"
      placeholder="请输入规格名称"
    >
      <template #button>
        <van-button size="small" type="primary" @click="onAddCustomSpec(index)">确认添加</van-button>
      </template>
    </van-field>
  </template>
  <van-cell-group title=" ">
    <van-cell title="库存及价格" value="批量设置" is-link @click="batch.show = true" v-if="!calendar.enable" />
    <van-cell title="库存及价格" value="批量设置" is-link @click="calendar.show = true" v-if="calendar.enable" />
    <van-cell :title="'当前有效规格组合' + combsCount + '种'" value="最多支持100种" />
    <van-cell value="若您的规格组合较多，建议您重新规划和设计，也可以多创建几种商品。超过100种会自动过滤删除" />
    <van-cell center title="开启日期" v-if="product.bizType && product.bizType.indexOf('BOOKING') > -1">
      <template #right-icon>
        <van-switch v-model="calendar.enable" size="24" />
      </template>
    </van-cell>
  </van-cell-group>
  <template v-for="(item, index) in combs" :key="item">
    <van-cell :title="item.title" style="margin:15px 0 0 0;" />
    <van-row v-if="!calendar.enable">
      <van-col span="12">
        <van-field v-model="item.price" label="价格" placeholder="请输入" type="number" />
      </van-col>
      <van-col span="12">
        <van-field v-model="item.stock" label="库存" placeholder="请输入" type="digit" />
      </van-col>
    </van-row>
    <van-field v-if="calendar.enable" clickable readonly v-model="value" label="日期" placeholder="点击设置" @click="onOpenCalendarPanel(index)"/>
  </template>
  <div style="height:70px;"></div>
  <van-action-bar>
    <van-action-bar-button type="danger" text="保存" @click="onSave" />
  </van-action-bar>
  <van-popup v-model:show="batch.show" position="bottom" :style="{ width: '100%' }">
    <van-form @submit="onBatchSet">
      <van-field
        v-model="batch.price"
        name="price"
        label="价格"
        placeholder=""
        type="number"
      />
      <van-field
        v-model="batch.stock"
        name="stock"
        label="库存"
        placeholder=""
        type="digit"
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </van-popup>
  <van-popup v-if="sonRefresh" v-model:show="calendar.show" position="bottom" :style="{ height: '80%', background: '#f8f8f8' }">
    <date-panel :days="calendar.days" @save="onResetDays" />
  </van-popup>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch, nextTick } from 'vue'
import { Toast } from 'vant'
// import dayjs from 'dayjs'
import DatePanel from './components/DatePanel'

export default {
  components: {
    DatePanel
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      custom: '',
      sonRefresh: true,
      productId: useRoute.query.productId,
      skus: [],
      seller: {},
      product: {},
      specs: [
        {
          options: [],
          selected: [],
          custom: ''
        }
      ],
      combs: [],
      combsCount: 0,
      combsIndex: 0,
      batch: {
        show: false,
        price: '',
        stock: ''
      },
      value: '',
      calendar: {
        enable: false,
        show: false,
        days: []
      }
    })
    const onClickLeft = () => {
      useRouter.go(-1)
    }
    const onRefresh = () => {
      state.sonRefresh = false
      nextTick(() => {
        state.sonRefresh = true
      })
    }
    const onAddCustomSpec = (index) => {
      state.specs[index].custom = state.specs[index].custom.replace(/\s+/g, '')
      if (state.specs[index].options.indexOf(state.specs[index].custom) > -1 || state.specs[index].custom === '') {
      } else {
        state.specs[index].options.push(state.specs[index].custom)
        state.specs[index].custom = ''
      }
    }
    const arrComb = (arr) => {
      const len = arr.length
      if (len >= 2) {
        const len1 = arr[0].length
        const len2 = arr[1].length
        const lenBoth = len1 * len2
        const items = new Array(lenBoth)
        let index = 0
        for (let i = 0; i < len1; i++) {
          for (let j = 0; j < len2; j++) {
            items[index] = arr[0][i] + '-' + arr[1][j]
            index++
          }
        }
        const newArr = new Array(len - 1)
        for (let i = 2; i < arr.length; i++) {
          newArr[i - 1] = arr[i]
        }
        newArr[0] = items
        return arrComb(newArr)
      } else {
        return arr[0]
      }
    }
    const onResetDays = (val) => {
      console.log(val)
      if (state.combsIndex === 0) {
        state.combs.map(v => {
          v.days = val
          return v
        })
      } else {
        state.combs[state.combsIndex].days = val
      }
      state.calendar.show = false
    }
    const onOpenCalendarPanel = (index) => {
      onRefresh()
      state.combsIndex = index
      state.calendar.show = true
      state.calendar.days = state.combs[state.combsIndex].days
      /*
      state.calendar.type = type
      state.calendar.show = true
      console.log(state.combs[state.combsIndex].days.map(item => { return item.day }))
      console.log(state.combs[state.combsIndex].days.map(item => { return dayjs(item.day).toDate() }))
      state.calendar.default = state.combs[state.combsIndex].days.map(item => { return dayjs(item.day).toDate() })
      state.show = true
      */
    }
    const onBatchSet = (values) => {
      for (const item of state.combs) {
        item.price = values.price
        item.stock = values.stock
      }
      state.batch.show = false
    }
    const onDelete = (index, index2) => {
      state.specs[index].selected.splice(index2, 1)
    }
    const onOpenPanel = (index) => {
      state.specs[index].show = true
      state.custom = ''
    }
    const onClosePanel = (index) => {
    }
    const onSave = () => {
      for (const item of state.combs) {
        item.s1 = item.title.split('-')[0] ?? ''
        item.s2 = item.title.split('-')[1] ?? ''
        item.s3 = item.title.split('-')[2] ?? ''
        item.s4 = item.title.split('-')[3] ?? ''
        item.s5 = item.title.split('-')[4] ?? ''
        /*
        if (!state.calendar.enable) {
          item.days = []
        }
        */
        if (item.days.length > 0) {
          item.days = item.days.filter(v => v.price !== '')
        }
      }
      post('/product.updateSku', {
        productId: state.productId,
        skus: state.combs,
        specCalendar: state.calendar.enable
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    watch(() => state.specs, (to, previous) => {
      const arr = []
      for (const item of to) {
        arr.push(item.selected)
      }
      const temp = arrComb(arr)
      state.combs = []
      for (const item of temp) {
        let has = false
        let hasItem = {}
        for (const item2 of state.skus) {
          if (item2.combTitle === item) {
            hasItem = item2
            has = true
            break
          }
        }
        if (has) {
          state.combs.push({
            title: item,
            price: hasItem.price,
            stock: hasItem.stock,
            days: hasItem.days
          })
        } else {
          state.combs.push({
            title: item,
            price: '',
            stock: '',
            days: []
          })
        }
      }
    }, { deep: true })
    watch(() => state.combs, (to, previous) => {
      state.combsCount = 0
      for (const item of to) {
        if (item.price !== '' && item.stock !== '') {
          state.combsCount++
        }
      }
    }, { deep: true })
    watch(() => state.calendar.enable, (to, previous) => {
      if (to) {
        state.combs.map(v => {
          v.price = ''
          v.stock = ''
          return v
        })
      }
    }, { deep: true })
    const init = () => {
      Toast.loading({ forbidClick: true })
      post('/product.get', {
        productId: state.productId
      }).then(res => {
        if (res.data.specs.length > 0) {
          state.specs = []
          for (const item of res.data.specs) {
            state.specs.push({
              options: item.options,
              name: item.name,
              selected: [],
              show: false,
              custom: ''
            })
          }
          state.product = res.data
          state.skus = res.data.skus ?? []
          if (state.skus.length > 0) {
            for (const item of res.data.skus) {
              if (item.days.length > 0) {
                state.calendar.enable = true
                break
              }
            }
            const s = []
            const s1 = []
            const s2 = []
            const s3 = []
            const s4 = []
            const s5 = []
            for (const item of res.data.skus) {
              s1.push(item.s1)
              s2.push(item.s2)
              s3.push(item.s3)
              s4.push(item.s4)
              s5.push(item.s5)
            }
            s[0] = Array.from(new Set(s1))
            s[1] = Array.from(new Set(s2))
            s[2] = Array.from(new Set(s3))
            s[3] = Array.from(new Set(s4))
            s[4] = Array.from(new Set(s5))
            for (let i = 0; i < state.specs.length; i++) {
              console.log(state.specs[i].options)
              console.log(s[i])
              state.specs[i].selected = s[i]
              state.specs[i].options = [...state.specs[i].options, ...s[i]]
              state.specs[i].options = Array.from(new Set(state.specs[i].options))
            }
            console.log(123)
            console.log(state.specs)
            for (const skuItem of state.skus) {
              let pp = ''
              if (state.specs.length > 0) {
                pp = skuItem.s1
              }
              if (state.specs.length > 1) {
                pp = pp + '-' + skuItem.s2
              }
              if (state.specs.length > 2) {
                pp = pp + '-' + skuItem.s3
              }
              if (state.specs.length > 3) {
                pp = pp + '-' + skuItem.s4
              }
              if (state.specs.length > 4) {
                pp = pp + '-' + skuItem.s5
              }
              skuItem.combTitle = pp
            }
          }
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onAddCustomSpec,
      onOpenPanel,
      onClosePanel,
      onOpenCalendarPanel,
      onResetDays,
      onBatchSet,
      onDelete,
      onSave,
      onClickLeft
    }
  }
}
</script>

<style scoped>
ul {
  overflow: hidden;
  background:#fff;
  padding:0 15px 15px 15px;
}
ul li {
  width:33%;
  float:left;
  margin:15px 0 0 0;
}
</style>
